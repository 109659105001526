body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-500 {
  font-weight: 500;
}

.HContainer {
  display: flex;
  flex-direction: row;
}

.VContainer {
  display: flex;
  flex-direction: column;
}

.j-center {
  justify-content: center !important;
}

.j-space-around {
  justify-content: space-around;
}

.a-center {
  align-items: center !important;
}

.center-text {
  text-align: center !important;
}

.justify-text {
  text-align: justify !important;
}
