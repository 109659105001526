.home-image-container {
  position: relative;
  /* width: 100vw; */
  height: 100vh;
  overflow: hidden;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .home-image-container {
    height: auto;
  }
}
